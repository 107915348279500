import * as React from 'react';
import { Link, navigate } from 'gatsby';

import Logo from '../../../static/ic-logo-clean-sm.png'

interface INavbarProps {
  color: 'navbar-dark' | 'navbar-light'
  stickColor: 'navbar-stick-dark' | 'navbar-stick-light'
}

const togglerStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none'
}

const Navbar: React.FunctionComponent<INavbarProps> = (props) => {
  const handleDemoClick = () => {
    (window as any).Calendly.showPopupWidget(
      'https://calendly.com/inkcloud/30min'
    );
  }

  const navbarDropback = React.useRef<HTMLDivElement>(null)

  const handleDropbackClick = () => {
    const elBody = (document as any).getElementsByTagName('BODY')[0]
    const wWidth = window?.innerWidth;

    if (wWidth < 992) {
      elBody?.classList?.toggle('navbar-open')
      navbarDropback.current?.classList.remove('backdrop', 'backdrop-navbar')
    }

  }


  const handleNavToggler = () => {
    const elBody = (document as any).getElementsByTagName('BODY')[0]

    elBody?.classList?.toggle('navbar-open')
    navbarDropback.current?.classList.add('backdrop', 'backdrop-navbar')
  }

  return (
    <nav className={`navbar navbar-expand-lg ${props.color} ${props.stickColor}`} data-navbar="sticky">
      <div id="dropback-container" ref={navbarDropback} onClick={handleDropbackClick}></div>
      <div className="container">
        <div className="navbar-left">
          <button className="d-lg-none" style={togglerStyle} onClick={handleNavToggler} type="button" id="mobile-nav-toggler"><span className="navbar-toggler-icon"></span></button>
          <Link className="navbar-brand" to={'/'}>
            <img className="logo-dark" src={Logo} alt="logo" />
            <img className="logo-light" src={Logo} alt="logo" />
          </Link>
        </div>

        <section className="navbar-mobile">

          <ul className="nav nav-navbar ml-auto mr-auto">
            {/* <li className="nav-item">
                <Link className="nav-link" to={'/'}>Home</Link>
              </li> */}

            <li className="nav-item">
              <span className="nav-link" onClick={() => {
                navigate('/product')
                handleDropbackClick()
              }}>Product</span>
            </li>

            <li className="nav-item">
              <span className="nav-link" onClick={() => {
                navigate('/solutions')
                handleDropbackClick()
              }}>Solutions <span className="arrow d-none d-lg-inline-block"></span></span>
              <nav className="nav">
                <span className="nav-link" onClick={() => {
                  navigate('/case-studies')
                  handleDropbackClick()
                }}>Case Studies</span>
              </nav>
            </li>
            <li className="nav-item d-lg-none">
              <span className="nav-link" onClick={() => {
                navigate('/case-studies')
                handleDropbackClick()
              }}>Case Studies</span>
            </li>


            <li className="nav-item">
              <span className="nav-link" onClick={() => {
                navigate('/about')
                handleDropbackClick()
              }}>About <span className="arrow d-none d-lg-inline-block"></span></span>
              <nav className="nav">
                <span className="nav-link" onClick={() => {
                  navigate('/insights')
                  handleDropbackClick()
                }}>Insights</span>
              </nav>

            </li>


            {/* <li className="nav-item">
                <Link className="nav-link" to={'/about'}>About <span className="arrow"></span></Link>
                <nav className="nav">
                  <Link className="nav-link" to={'/blog'}>Blog</Link>
                </nav>
              </li> */}

            <li className="nav-item">
              <span className="nav-link" onClick={() => {
                navigate('/contact')
                handleDropbackClick()
              }}>Contact</span>
            </li>

            {/* <li className="nav-item">
                <a className="nav-link" href="#">Company <span className="arrow"></span></a>
                <nav className="nav">
                  <Link className="nav-link" to={'/about'}>About</Link>
                  <Link className="nav-link" to={'#contact'}>Contact</Link>
                </nav>
              </li> */}
          </ul>
        </section>
        <a
          onClick={handleDemoClick}
          className="btn btn-sm btn-round btn-success d-none d-lg-inline-block mr-2"
          href="#"
        >Demo Now</a>

        {/* <div className="text-right">
          
        </div>
        <div className="row">

        </div> */}
      </div>
    </nav>
  );
};

export default Navbar;
